import React, { Component } from 'react';
import { MdOutlineChevronLeft } from 'react-icons/md';
import { connect } from 'react-redux';
// import LoadingOverlay from 'react-loading-overlay';
import { userActions } from '../../_actions';
import { FiChevronRight } from "react-icons/fi";
import moment from "moment";
import { TiEdit } from "react-icons/ti";

class Send extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedLanguage: '',
    };
  }
  componentDidMount() {
    const studentId = this.props.match.params.id
    this.props.dispatch(userActions.previousScoreListing({ studentId }))
  }


  continueClicked = () => {
    // Handle continue button click event here
    console.log('Continue button clicked for language:', this.state.selectedLanguage);
  }

  onClickMenu = (ele) => {
    if (window.localStorage.getItem(`currentQuestion${this.props.match?.params?.id}`)) {
      this.props.history.push(`/app/assessment/${this.props.match.params.id}`)

    } else {
      this.props.history.push(`/app/screening/${this.props.match.params.id}`)
    }
  };

  handelOnReport = (id) => {
    this.props.history.push(`/app/report/${this.props.match.params.id}/${id}`)
  }
  
  handleEdit = () =>{
    this.props.history.push(`/app/editStudent/${this.props.match.params.id}`)

  }




  render() {
    const { users, ele } = this.props;
    return (
      <>
        <div>
          {/* <LoadingOverlay active={loading} className="temp001" spinner text="Please wait..." /> */}
        </div>

        <div className="h-screen xl:py-10 py-6 overflow-y-auto item-center flex justify-center">
          <div className='lg:w-7/12 w-11/12 mx-auto'>
            <div className='bg-[#F6F0FF] px-2 py-4 space-y-6'>
              <div className="flex items-center justify-between lg:p-6 py-2">
                <div className="rounded-full p-1 relative bg-[#fff] shadow-lg" onClick={() => this.props.history.goBack()}>
                  <MdOutlineChevronLeft className='text-[#4E2C7A]' size={30} />
                  
                </div>
                <div className="">
                  {/* <h1>Student Profile</h1> */}
                </div>
                <div>
                </div>
              </div>
            </div>
            <div className='-mt-10 flex justify-center items-center'>
              <div className=' relative'>

              <img className='w-24 h-24 mx-auto rounded-full border-2 p-1 border-[#4E2C7A]' src={users?.screenList?.studentProfile?.profileImg||"/img/Image.png"} alt='' />
              <div className=' absolute text-2xl -bottom-2 right-7 bg-[#4E2C7A] rounded-full p-1'>

              <TiEdit onClick={()=>this.handleEdit()} className=' text-white' title='Edit'/>
              </div>
              </div>
            </div>
            <div className='flex flex-col items-center py-6'>
              <h2 className=' text-bold text-lg'> {users?.screenList?.studentProfile?.fullName}</h2>
              <h2 className=' text-[#4a2382] text-sm'>Class: {users?.screenList?.studentProfile?.grade}</h2>
            </div>
            <div className='space-y-4'>
              <div className='flex justify-between items-center border-b border-[#D9D9D975] cursor-pointer bg-[#F6F0FF] px-5 py-3 rounded-full' onClick={() => this.onClickMenu(ele)}>
                <div className='flex flex-col space-y-1'>
                  <div className='flex items-center space-x-2'>
                    <div className=' text-white rounded-full w-10 h-10 flex justify-center items-center'>
                      <img src="/img/search-icon.png" alt="" className='rounded-full w-full' />
                    </div>
                    <div>
                      <p className='text-[#000] text-[16px] font-[600]'>{window.localStorage.getItem(`currentQuestion${this.props.match?.params?.id}`) ? "Resume Screening" : "Start New Screening"}</p>
                    </div>
                  </div>
                </div>
                <div>
                  <FiChevronRight color='#4E2C7A' size={30} />
                </div>
              </div>


              {Array.isArray(users?.screenList?.listingData) && users?.screenList?.listingData.length > 0 ? users?.screenList?.listingData.map((ele) => {
                return (
                  <div className='flex justify-between items-center border border-[#4E2C7A] cursor-pointer  px-5 py-3 rounded-full'>
                    <div>
                      <p className='text-[#4E2C7A] text-[16px] font-[600]'>{moment(ele.createdAt).format("DD-MM-YYYY")}</p>
                    </div>
                    <div>
                      <p className='text-[#4E2C7A]' onClick={() => this.handelOnReport(ele._id)}>Review</p>
                    </div>
                  </div>
                )
              }) : <div>No Screenings Found</div>}
            </div>

          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { users } = state;
  return {
    users,
  };
}

export default connect(mapStateToProps)(Send);
