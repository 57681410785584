
import React, { Component } from "react";
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import { AiOutlineMail } from 'react-icons/ai';
import Timer from 'otp-timer';
import { ColorRing } from 'react-loader-spinner'
class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fieldLogin: {
        fullName: "",
        email: "",
        mobNo: "",
        OTP: "",
        countryCode: ""
      },
      errorLogin: {},
      otpSentRegister: false,
      showPassword: false,
      showConfirmPassword: false,
      selectedCountry: "",
      diaCode: "",
      selectedRadio: 'PARENT',
      countryCode: ""
    };
    this.inputChange = this.inputChange.bind(this);
    this.registerSubmit = this.registerSubmit.bind(this);
    this.togglePasswordVisibility = this.togglePasswordVisibility.bind(this);
    this.toggleConfirmPasswordVisibility = this.toggleConfirmPasswordVisibility.bind(this);
  }
  componentDidMount() {
    if (this.props?.history?.location.state) {
      this.setState(this.props?.history?.location.state)
    }
    this.props.dispatch(userActions?.getAllCountryCodeListApi())
  }

  static getDerivedStateFromProps(nextProps, prevState) {

    // Check if API data exists and countryCode is set in state
    if (nextProps?.users?.getAllCountryCodeListApi && prevState?.countryCode) {
      const getCountry = nextProps.users.getAllCountryCodeListApi.find(
        (ele) => ele.code === prevState.countryCode
      );

      if (getCountry) {
        return {
          ...prevState, // Keep previous state
          formData: {
            ...prevState.formData,
            countryCode: getCountry._id, // Assign found country ID
          },
          otpSentLogin: nextProps.otpSentLogin || false, // Update otpSentLogin
        };
      }
    }


    if (nextProps?.match?.params?.mobNo) {
      if (nextProps?.match?.params?.mobNo.includes("@")) {
        return {
          fieldLogin: {
            ...prevState.fieldLogin,
            email: nextProps.match.params.mobNo,
          },
        };
      } else {
        return {
          fieldLogin: {
            ...prevState.fieldLogin,
            mobNo: nextProps.match.params.mobNo,
          },
        };
      }
    }

    // Default: Return nextProps as part of new state
    return {
      ...prevState,
      otpSentLogin: nextProps.otpSentLogin || false,
    };
  }



  handleRadioChange = (event) => {
    this.setState({ selectedRadio: event.target.id });
  };

  inputChange(e) {
    const { name, value } = e.target;

    // Restrict mobile number to 10 digits
    if (name === "mobNo" && value.length > 10) {
      return;
    }

    this.setState(prevState => ({
      fieldLogin: {
        ...prevState.fieldLogin,
        [name]: name === "countryCode" ? String(value) : value // Ensure countryCode is a string
      }
    }));
  }




  registerSubmit(e) {
    e.preventDefault();
    if (this.handleValidationLogin()) {

      const { email, mobNo, fullName, countryCode } = this.state.fieldLogin;
      const data = {
        fullName,
        email,
        mobNo,
        countryCode,
        type: this.state.selectedRadio
      };
      this.props.dispatch(userActions.saveUserInfo(data, this.props));
    }
  }

  resendOtpSubmit = (e) => {
    if (this.handleValidationLogin()) {
      let data = {
        firstName: this.state.fieldslogin.firstName,
        lastName: this.state.fieldslogin.lastName,
        email: this.state.fieldslogin.email,
        // mobNo: this.state.fieldslogin.mobNo,
        password: this.state.fieldslogin.password,
        refByCode: this.props.match.params.id ? this.props.match.params.id : (this.state.fieldslogin.refByCode ? this.state.fieldslogin.refByCode : "ADMIN")
      }
      this.props.dispatch(userActions.register(data, this.props));
    }
  }

  otpSubmit = (e) => {
    e.preventDefault();
    let { token } = this.props.registeruser;
    let { OTP } = this.state.fieldLogin;
    this.props.dispatch(userActions.registerValidateOtp({
      token: token,
      otp: OTP
    }, this.props));
  }

  handleValidationLogin() {
    const { email, fullName } = this.state.fieldLogin;
    const errorslogin = {};

    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!email || !emailPattern.test(email)) {
      errorslogin["email"] = "Enter a valid email address.";
    }

    // const mobNoPattern = /^\d{7,15}$/;
    // if (!mobNo || !mobNo.match(mobNoPattern)) {
    //   errorslogin["mobNo"] = "Mobile number must be 7 to 15 digits.";
    // }
    // if (!mobNo || mobNo.length !==10) {
    //   errorslogin["mobNo"] = "Mobile number must be 10 digits.";
    // }

    if (!fullName.trim()) {
      errorslogin["fullName"] = "Full name is required.";
    }

    this.setState({ errorLogin: errorslogin });
    return Object.keys(errorslogin).length === 0;
  }

  onClickMenu = (url) => {
    this.props.history.push(url);
  }

  togglePasswordVisibility() {
    this.setState(prevState => ({
      showPassword: !prevState.showPassword,
    }));
  }

  toggleConfirmPasswordVisibility() {
    this.setState(prevState => ({
      showConfirmPassword: !prevState.showConfirmPassword,
    }));
  }

  handleKeyPress = (event) => {
    const keyPressed = event.key.toLowerCase();
    const country = this.props.users.countryCodeList.find(
      (element) => element && element.name.toLowerCase().startsWith(keyPressed)
    );
    if (country) {
      this.setState(country);
    }
  };

  render() {
    const { otpSentRegister, loading, users } = this.props;
    return (
      <div>
        {loading && <ColorRing
          visible={true}
          height="80"
          width="80"
          ariaLabel="color-ring-loading"
          wrapperStyle={{}}
          wrapperClass="color-ring-wrapper"
          colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
        />}

        <section className="flex items-center w-full">
          <div className="bg-[#fff] w-full relative lg:block hidden">
            <img className="absolute top-0 left-0" src="/img/Vector.png" alt="" />
            <div className="flex flex-col justify-center items-center h-screen">
              <div>
                <img src="/img/logo-login.png" alt="" />
              </div>
              <div className="py-6">
                <h1 className="text-[#4E2C7A] text-[60px] font-[700] text-center">First Screen By Orkids</h1>
              </div>
            </div>
            <img className="absolute bottom-0 right-0" src="/img/Vector-1.png" alt="" />
          </div>
          <div className="bg-[#F6F0FF] w-full overflow-y-auto h-screen overflow-hidden">
            <div className="flex-col justify-center flex w-5/6 mx-auto space-y-2 xl:w-3/6 h-screen">
              <div className="text-center">
                <h4 className="text-[#4E2C7A]">Welcome to First Screen, Please</h4>
                <h1 className="text-[45px] font-[700] text-[#4E2C7A]">Sign Up</h1>
              </div>
              <div className="space-y-2">
                <label className="text-sm font-medium text-[#4E2C7A]">* Full Name</label>
                <div className="relative flex items-center">
                  <input
                    className={`w-full border px-4 py-2.5 rounded-md focus:outline-none text-base font-normal placeholder-white placeholder:text-[#c1bfe9] bg-transparent text-[#4E2C7A] ${!this.state.errorLogin["fullName"] ? "border-opacity-20 border-opacity-100 border placeholder-white font-base" : "border-opacity-100 border-none ring-2 ring-red-300"}`}
                    id="fullName"
                    name="fullName"
                    placeholder="Full name"
                    type="text"
                    onChange={this.inputChange}
                    value={this.state.fieldLogin["fullName"]}
                  />
                </div>
                {this.state.errorLogin["fullName"] && <div className="mt-1 font-serif text-red-500 invalid-feedback">{this.state.errorLogin["fullName"]}</div>}
              </div>
              <div className="space-y-2">
                <label className="text-sm font-medium text-[#4E2C7A]">* Mobile</label>
                <div className="relative flex items-center">
                  <select
                    className="absolute left-3 bg-transparent text-[#4E2C7A] font-medium focus:outline-none"
                    value={this.state.fieldLogin["countryCode"] || "+91"}
                    onChange={this.inputChange}
                    name="countryCode"
                  >
                    {Array.isArray(users?.getAllCountryCodeListApi) && users?.getAllCountryCodeListApi?.length > 0 && users?.getAllCountryCodeListApi.map((ele) => {
                      return <option value={ele?._id}>{ele?.dialCode} {ele?.emoji} </option>
                    })}

                    {/* Add more country codes */}
                  </select>
                  <input
                    disabled={otpSentRegister}
                    className={`w-full border px-4 py-2.5 rounded-md focus:outline-none text-base font-normal placeholder-white placeholder:text-[#c1bfe9] bg-transparent text-[#4E2C7A] ml-10 ${!this.state.errorLogin["mobNo"] ? "border-opacity-20 border-opacity-100 border placeholder-white font-base" : "border-opacity-100 border-none ring-2 ring-red-300"}`}
                    id="mobNo"
                    name="mobNo"
                    placeholder="Mobile"
                    type="number"
                    onChange={this.inputChange}
                    value={this.state.fieldLogin["mobNo"]}
                  />
                </div>
                {this.state.errorLogin["mobNo"] && <div className="mt-1 font-serif text-red-500 invalid-feedback">{this.state.errorLogin["mobNo"]}</div>}
              </div>
              <div className="space-y-2">
                <label className="text-sm font-medium text-[#4E2C7A]">* Email</label>
                <div className="relative flex items-center">
                  <AiOutlineMail className="absolute right-3 text-gray-400" />
                  <input
                    disabled={otpSentRegister}
                    className={`w-full border px-4 py-2.5 rounded-md focus:outline-none text-base font-normal placeholder-white placeholder:text-[#c1bfe9] bg-transparent text-[#4E2C7A] ${!this.state.errorLogin["email"] ? "border-opacity-20 border-opacity-100 border placeholder-white font-base" : "border-opacity-100 border-none ring-2 ring-red-300"}`}
                    id="email"
                    name="email"
                    placeholder="example@gmail.com"
                    type="email"
                    onChange={this.inputChange}
                    value={this.state.fieldLogin["email"]}
                  />
                </div>
                {this.state.errorLogin["email"] && <div className="mt-1 font-serif text-red-500 invalid-feedback">{this.state.errorLogin["email"]}</div>}
              </div>
              <div className="space-y-2">
                <label className="text-sm font-medium text-[#4E2C7A]">* I am a</label>
                <div className="flex">
                  <div className="flex items-center border-gray-300 px-3 py-1 border rounded  me-4">
                    <input
                      id="PARENT"
                      type="radio"
                      name="inline-radio-group"
                      checked={this.state.selectedRadio === 'PARENT'}
                      onChange={this.handleRadioChange}
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label htmlFor="PARENT" className="ms-2 text-sm font-medium text-[#4E2C7A]">Parent</label>
                  </div>
                  <div className="flex border-gray-300 px-3 py-1 border items-center me-4">
                    <input
                      id="TEACHER"
                      type="radio"
                      name="inline-radio-group"
                      checked={this.state.selectedRadio === 'TEACHER'}
                      onChange={this.handleRadioChange}
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label htmlFor="TEACHER" className="ms-2 text-sm font-medium text-[#4E2C7A]">Teacher</label>
                  </div>
                  <div className="flex border-gray-300 px-3 py-1 border items-center me-4">
                    <input
                      id="OTHER"
                      type="radio"
                      name="inline-radio-group"
                      checked={this.state.selectedRadio === 'OTHER'}
                      onChange={this.handleRadioChange}
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label htmlFor="OTHER" className="ms-2 text-sm font-medium text-[#4E2C7A]">Other</label>
                  </div>
                </div>
              </div>
              {otpSentRegister &&
                <div className="">
                  <div className="">
                    <label className="text-sm font-medium text-[#4E2C7A]">OTP</label>
                  </div>
                  <input
                    className={`w-full border px-4 py-2.5 rounded-md focus:outline-none text-base font-normal text-white placeholder-white placeholder:text-[#c1bfe9] bg-transparent ${!this.state.errorLogin["OTP"] ? "border-opacity-20  border placeholder-white font-base" : "border-opacity-100 border-none ring-2 ring-red-300"}`}
                    id="OTP"
                    name="OTP"
                    value={this.state.fieldLogin["OTP"]}
                    onChange={this.inputChange}
                    placeholder="Enter OTP"
                    type="text"
                  />
                  {this.state.errorLogin["OTP"] && <div className="w-5/6 invalid-feedback text-red-500">{this.state.errorLogin["OTP"]}</div>}
                </div>
              }
              {otpSentRegister &&
                <div className="flex items-center justify-between">
                  <div className="">
                    <Timer textColor={"white"} seconds={1} minutes={2} ButtonText="Resend Otp" resend={this.resendOtpSubmit} />
                  </div>
                </div>
              }
              {otpSentRegister ?
                <div className="">
                  <button className="w-full py-3 mx-auto text-white transition duration-150 ease-in-out rounded-[8px] cursor-pointer all-btn-color lg:font-bold focus:outline-none focus:underline" type="button" onClick={this.otpSubmit}>Register</button>
                </div>
                :
                <div className="">
                  <button className="w-full py-3 mx-auto text-white transition duration-150 ease-in-out rounded-[8px] cursor-pointer all-btn-color lg:font-bold focus:outline-none focus:underline" type="submit" onClick={this.registerSubmit}>Sign Up</button>
                </div>
              }
              <div className="py-0">
                <p className="text-center text-[18px] font-[400] text-[#A09DC5]">Already have an account? <span onClick={() => this.onClickMenu('/login')} className="text-[#508EF3] cursor-pointer">Sign in</span></p>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}


function mapStateToProps(state) {
  const { users } = state;
  return {
    users,
  };
}

export default connect(mapStateToProps)(Signup);
