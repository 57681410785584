import React, { Component } from 'react';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { MdOutlineChevronLeft } from 'react-icons/md';
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import { FaPlus, FaMinus } from "react-icons/fa6";
import { GoDotFill } from "react-icons/go";
import moment from 'moment';
import pdf from "./PdfDone.png"

const categories = ["Memory", "Reading", "Writing", "Oral", "Maths", "Motor", "Social", "Attention", "Executive"];


class MyChart extends Component {
  constructor(props) {
    super(props);
    this.chartRef = React.createRef();
    this.state = {
      languageList: true,
      translatedData: {},
      activeIndex: null
    }
  }

  componentDidMount() {
    const data = {
      "keyWord": "",
      "pageNo": 1,
      "sortBy": "createdAt",
      "sortOrder": "asc",
      "fromDate": "",
      "toDate": "",
      "size": 23
    }
    this.props.dispatch(userActions.reportDataForStudentId({
      studentId: this.props.match.params.id,
      scoreId: this.props.match.params.scoreId,
    }));
    this.props.dispatch(userActions.getStudentList(data))


  }

  componentDidUpdate(prevProps) {
    if (prevProps.users.reportDataForStudentId !== this.props.users.reportDataForStudentId) {
      this.destroyChart();
      this.buildChart();
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {

    function arrayToObjectFunctionLanguage(arr) {
      let obj = {}
      for (let i = 0; i < arr.length; i++) {
        obj[arr[i].name] = arr[i]["value"][window.localStorage.getItem("language")]
      }
      return obj
    }

    if (nextProps.users && nextProps.users.getLanguageSetting2List && Array.isArray(nextProps.users.getLanguageSetting2List)) {
      const objects = arrayToObjectFunctionLanguage(nextProps.users.getLanguageSetting2List)
      return {
        translatedData: objects
      }
    }

    return null;
  }

  componentWillUnmount() {
    this.destroyChart();
  }

  // buildChart() {
  //   const { reportDataForStudentId } = this.props.users;
  //   if (!reportDataForStudentId) return;

  //   const backgroundColorChart = categories.map((category) => {
  //     const valueData = (reportDataForStudentId.scoreDetails[category] / reportDataForStudentId.scoreDetails[`${category}TotalNumber`]) * 100;
  //     if (valueData >= 60) return '#FC3333';
  //     if (valueData >= 40) return '#FEC802';
  //     return '#81E701';
  //   });

  //   const ctx = this.chartRef.current.getContext('2d');
  //   this.myChart = new Chart(ctx, {
  //     type: 'pie',
  //     data: {
  //       datasets: [{
  //         backgroundColor: backgroundColorChart,
  //         data: categories.map(() => 10), 
  //       }],
  //       labels: categories,
  //     },
  //     options: {
  //       responsive: true,
  //       width: '100%',
  //       plugins: {
  //         legend: {
  //           display: false,
  //         },
  //         datalabels: {
  //           color: '#000000',
  //           formatter: function (value, context) {
  //             return context.chart.data.labels[context.dataIndex];
  //           },
  //           align: 'start',
  //           anchor: 'end',
  //           offset: -5,
  //           padding: 10,
  //           rotation: function (context) {
  //             const angle = (context.chart.getDatasetMeta(0).data[context.dataIndex].startAngle +
  //               context.chart.getDatasetMeta(0).data[context.dataIndex].endAngle) / 2;
  //             const degrees = angle * (180 / Math.PI);
  //             return degrees + 90;
  //           },
  //           textAlign: 'center',
  //           font: {
  //             weight: 'bold'
  //           },
  //           textStrokeColor: '#FFFFFF',
  //           textStrokeWidth: 6
  //         }
  //       }
  //     },
  //     plugins: [ChartDataLabels],
  //   });
  // }

  buildChart() {
    const { reportDataForStudentId } = this.props.users;
    if (!reportDataForStudentId) return;

    const backgroundColorChart = categories.map((category) => {
      const valueData = (reportDataForStudentId.scoreDetails[category] / reportDataForStudentId.scoreDetails[`${category}TotalNumber`]) * 100;
      if (valueData >= 60) return '#FC3333';
      if (valueData >= 40) return '#FEC802';
      return '#81E701';
    });

    const ctx = this.chartRef.current.getContext('2d');
    this.myChart = new Chart(ctx, {
      type: 'pie',
      data: {
        datasets: [{
          backgroundColor: backgroundColorChart,
          data: categories.map(() => 10), // Assuming you want equal slices
        }],
        labels: categories,
      },
      options: {
        responsive: true,
        width: '100%',
        plugins: {
          legend: {
            display: false,
          },
          datalabels: {
            color: '#000000',
            formatter: function (value, context) {
              return context.chart.data.labels[context.dataIndex];
            },
            align: 'center',
            anchor: 'end',
            offset: -5,
            padding: 10,
            rotation: function (context) {
              const angle = (context.chart.getDatasetMeta(0).data[context.dataIndex].startAngle +
                context.chart.getDatasetMeta(0).data[context.dataIndex].endAngle) / 2;
              const degrees = angle * (180 / Math.PI);
              return degrees + 90;
            },
            textAlign: 'center',
            font: {
              weight: 'bold'
            },
            textStrokeColor: '#FFFFFF',
            textStrokeWidth: 6
          }
        },
        animation: false, // Disable animation
      },
      plugins: [ChartDataLabels],
    });
  }




  destroyChart() {
    if (this.myChart) {
      this.myChart.destroy();
    }
  }

  onClickMenu = (url) => {
    this.props.history.push(url);
  };



  setActiveIndex = (index) => {
    this.setState({ activeIndex: index });
  };

  render() {
    const { users } = this.props;
    const { reportDataForStudentId, studentList } = users;

    if (reportDataForStudentId && reportDataForStudentId.screeningMode && this.state.languageList) {
      this.props.dispatch(userActions.getLanguageSetting2List({ languageShortName: reportDataForStudentId.screeningMode }))
      this.setState({ languageList: false })
    }

    const translatedData = this.state.translatedData



    // const { translatedData } = this.props;
    const { activeIndex } = this.state;
    const dataFind = studentList?.studentList?.list?.find((ele) => String(ele._id) === String(reportDataForStudentId?.StudentProfile?._id))
    return (
      <section className='overflow-hidden overflow-y-auto'>
        <div className="xl:w-7/12 w-11/12 mx-auto py-10">
          <div className='bg-[#F6F0FF] px-2 py-4 space-y-6'>
            <div className="flex items-center justify-between p-6 py-2">
              <div className="rounded-full p-1 bg-[#fff] shadow-lg" onClick={() => this.onClickMenu('/app/dashboard')}>
                <MdOutlineChevronLeft className='text-[#4E2C7A]' size={30} />
              </div>
              <div>
                <h1 className=' text-2xl'>Report</h1>
              </div>
              <div>
              </div>
            </div>
          </div>
          <div className='py-2'>
            <div className='flex items-center space-x-4 py-6'>
              <div>
                <img src={reportDataForStudentId?.StudentProfile?.profileImg || '/img/Image.png'} alt="" className='w-24 h-24 rounded-full' />
              </div>
              <div>
                <h2 className=' text-2xl capitalize'>{reportDataForStudentId?.StudentProfile?.fullName || ""}</h2>
                {/* <p>{reportDataForStudentId?.StudentProfile?.grade || ""} Grade</p> */}
                <p>{new Date().getFullYear() - moment(dataFind?.dob).year()} Year</p>
                <p className='text-[#4E2C7A] text-[16px] font-[600]'>{moment(dataFind?.createdAt).format("DD-MM-YYYY")} (Date of Assessment)</p>
              </div>
            </div>
            <div className='md:flex md:flex-col  md:space-y-0 space-y-6 items-center gap-8'>
              <div className='md:w-[400px] md:h-[400px] w-[350px] h-[350px] p-5  '>
                <canvas width={400} height={400} ref={this.chartRef} id="myPieChart" />
              </div>
              <div className='space-y-2 md:flex'>
                <p className='text-lg font-bold flex gap-2 items-center'><span className=''><GoDotFill color='#FC3333' size={30} /></span> Significant Concerns  </p>
                <p className='text-lg font-bold flex gap-2 items-center'><span className=''><GoDotFill color='#FEC802' size={30} /></span> Mild Concerns  </p>
                <p className='text-lg font-bold flex gap-2 items-center'><span className=''><GoDotFill color='#81E701' size={30} /></span> No Concerns  </p>
              </div>
            </div>


            <div class="relative overflow-x-auto py-4">
              <p className="px-6 py-3 text-[#4E2C7A] text-center font-bold text-lg border"> {translatedData["If_3_or_more_skills_are_in_red,_a_complete_Psycho_Educational_Assessment_is_recommended._It_is_also_recommended_to_get_a_vision_and_hearing_assessment_done_before_a_full_psycho_educational_assessment_is_done."] ? translatedData["Final_Interpretation_of_the_Screening_Test"] : "Final Interpretation of the Screening Test"}</p>
              <table className="w-full ">
                <tbody className='border'>
                  <tr className="">
                    <th className="px-6 py-4 text-gray-600 border border-r">
                      1
                    </th>
                    <td className="px-6 py-4 text-gray-600 border border-r">{translatedData["If_3_or_more_skills_are_in_red,_a_complete_Psycho_Educational_Assessment_is_recommended._It_is_also_recommended_to_get_a_vision_and_hearing_assessment_done_before_a_full_psycho_educational_assessment_is_done."] ? translatedData["If_3_or_more_skills_are_in_red,_a_complete_Psycho_Educational_Assessment_is_recommended._It_is_also_recommended_to_get_a_vision_and_hearing_assessment_done_before_a_full_psycho_educational_assessment_is_done."] : "If 3 or more skills are in red, a complete Psycho Educational Assessment is recommended. It is also recommended to get a vision and hearing assessment done before a full psycho educational assessment is done."}</td>
                  </tr>
                  <tr className="">
                    <th className="px-6 py-4 text-gray-600 border border-r">
                      2
                    </th>
                    <td className="px-6 py-4 text-gray-600 border border-r"> {translatedData["If_2_or_less_skills_are_in_red,_combined_with_yellow/green,_it_is_recommended_to_work_with_the_child_on_the_yellow_and_red_coded_skills_for_3_months_and_then_retake_the_screening_test."] ? translatedData["If_2_or_less_skills_are_in_red,_combined_with_yellow/green,_it_is_recommended_to_work_with_the_child_on_the_yellow_and_red_coded_skills_for_3_months_and_then_retake_the_screening_test."] : "If 2 or less skills are in red, combined with yellow/green, it is recommended to work with the child on the yellow and red coded skills for 3 months and then retake the screening test."}</td>
                  </tr>
                  <tr className="">
                    <th className="px-6 py-4 text-gray-600 border border-r">
                      3
                    </th>
                    <td className="px-6 py-4 text-gray-600 border border-r"> {translatedData["If_combination_of_yellow_and_green_in_skills,_it_is_recommended_to_work_with_the_child_on_the_yellow_coded_skills_for_3_months_and_then_retake_the_screening_test."] ? translatedData["If_combination_of_yellow_and_green_in_skills,_it_is_recommended_to_work_with_the_child_on_the_yellow_coded_skills_for_3_months_and_then_retake_the_screening_test."] : "If combination of yellow and green in skills, it is recommended to work with the child on the yellow coded skills for 3 months and then retake the screening test."}</td>
                  </tr>
                  <tr className="">
                    <th className="px-6 py-4 text-gray-600 border border-r">
                      4
                    </th>
                    <td className="px-6 py-4 text-gray-600 border border-r">{translatedData["If_all_skills_in_green,_relax,_there_are_no_concerns."] ? translatedData["If_all_skills_in_green,_relax,_there_are_no_concerns."] : "If all skills in green, relax, there are no concerns."}</td>
                  </tr>
                </tbody>
              </table>
            </div>




            <div className='text-center'>
              <a href={reportDataForStudentId?.reportUrl} target='_blank' rel="noreferrer">
                <button onClick={this.chartGenerateFun} className="inline-flex items-center gap-2 mt-4 px-4 py-2 text-[#4E2C7A] font-bold text-2xl rounded">Skill Analysis <span><img src={pdf} className=' w-10 h-10' alt='' />

                </span></button>
              </a>
            </div>
          </div>

          <div className="w-full bg-white shadow-md rounded-lg divide-y-4 divide-white">
            <div className="">
              <div
                className="flex justify-between w-full text-left p-4 focus:outline-none bg-[#F6F0FF]"
                onClick={() => this.setActiveIndex(activeIndex === 0 ? null : 0)}
              >
                <p>{translatedData["Reading"] ? translatedData["Reading"] : "Reading"}</p>
                <p>
                  {activeIndex === 0 ? <FaMinus color='#000' /> : <FaPlus color='#000' />}
                </p>
              </div>
              {activeIndex === 0 && (
                <div className="p-4">
                  <div className="pb-2">
                    {translatedData["Coding_decoding"] ? translatedData["Coding_decoding"] : "Coding decoding"}
                  </div>
                  <div className="border-b pb-2">
                    {translatedData["Ability_of_the_child_to_recognize_the_basic_sounds_and_sound_blends,_called_phonemes,_that_make_up_a_word_and_to_know_its_meaning_and_recognize_it_in_context."] ? translatedData["Ability_of_the_child_to_recognize_the_basic_sounds_and_sound_blends,_called_phonemes,_that_make_up_a_word_and_to_know_its_meaning_and_recognize_it_in_context."] : "Ability of the child to recognize the basic sounds… to know its meaning and recognize it in context."}
                  </div>
                  <div className="pb-2">
                    {translatedData["Comprehension"] ? translatedData["Comprehension"] : "Comprehension"}
                  </div>
                  <div className="border-b pb-2">
                    {translatedData["Ability_of_the_child_to_process_text,_understand_i…_to_integrate_with_what_the_he/she_already_knows."] ? translatedData["Ability_of_the_child_to_process_text,_understand_i…_to_integrate_with_what_the_he/she_already_knows."] : "Ability of the child to process text, understand i… and to integrate with what he/she already knows."}
                  </div>
                  <div className="pb-2">
                    {translatedData["Vocabulary"] ? translatedData["Vocabulary"] : "Vocabulary"}
                  </div>
                  <div className="border-b pb-2">
                    {translatedData["Ability_of_the_child_to_use_variety_of_words_appro…ly_to_communicate_either_in_oral_or_written_form."] ? translatedData["Ability_of_the_child_to_use_variety_of_words_appro…ly_to_communicate_either_in_oral_or_written_form."] : "Ability of the child to use variety of words appro…ly to communicate either in oral or written form."}
                  </div>
                  <div className="pb-2">
                    {translatedData["Reading_pace"] ? translatedData["Reading_pace"] : "Reading pace"}
                  </div>
                  <div className="border-b pb-2">
                    {translatedData["bility_of_the_child_to_read_fluently."] ? translatedData["bility_of_the_child_to_read_fluently."] : "Ability of the child to read fluently."}
                  </div>
                </div>
              )}
            </div>
            <div className="">
              <div
                className="flex justify-between w-full text-left p-4 focus:outline-none bg-[#F6F0FF]"
                onClick={() => this.setActiveIndex(activeIndex === 1 ? null : 1)}
              >
                {translatedData["Writing"] ? translatedData["Writing"] : "Writing"}
                <p>
                  {activeIndex === 1 ? <FaMinus color='#000' /> : <FaPlus color='#000' />}
                </p>
              </div>
              {activeIndex === 1 && (
                <div className="p-4">
                  <div className="pb-2">
                    {translatedData["Spellings"] ? translatedData["Spellings"] : "Spellings"}
                  </div>
                  <div className="border-b pb-2">
                    {translatedData["The_ability_of_the_child_to_identify_the_sound;_and_write_and_name_the_letters_of_a_wood_correctly."] ? translatedData["The_ability_of_the_child_to_identify_the_sound;_and_write_and_name_the_letters_of_a_wood_correctly."] : "The ability of the child to identify the sound; and write and name the letters of a word correctly."}
                  </div>
                  <div className="pb-2">
                    {translatedData["Written_Expression"] ? translatedData["Written_Expression"] : "Written Expression"}
                  </div>
                  <div className="border-b pb-2">
                    {translatedData["Ability_of_the_child_to_generate_ideas,_construct_meaningful_sentences,_sequence_and_organise_their_ideas_into_paragraphs_and_use_grammer_appropriately."] ? translatedData["Ability_of_the_child_to_generate_ideas,_construct_meaningful_sentences,_sequence_and_organise_their_ideas_into_paragraphs_and_use_grammer_appropriately."] : "Ability of the child to generate ideas, construct meaningful sentences, sequence and organise their ideas into paragraphs and use grammar appropriately."}
                  </div>
                  <div className="pb-2">
                    {translatedData["Handwriting/Graphomotor"] ? translatedData["Handwriting/Graphomotor"] : "Handwriting/Graphomotor"}
                  </div>
                  <div className="border-b pb-2">
                    {translatedData["Ability_of_the_child_to_hold_a_pencil/pen_with_proper_grip_and_use_visual_perceptual_skills_to_write."] ? translatedData["Ability_of_the_child_to_hold_a_pencil/pen_with_proper_grip_and_use_visual_perceptual_skills_to_write."] : "Ability of the child to hold a pencil/pen with proper grip and use visual perceptual skills to write."}
                  </div>
                  <div className="pb-2">
                    {translatedData["Mechanics"] ? translatedData["Mechanics"] : "Mechanics"}
                  </div>
                  <div className="border-b pb-2">
                    {translatedData["Ability_of_the_child_to_use_established_conventions_used_for_words,_including_spellings,_punctuations_and_grammar."] ? translatedData["Ability_of_the_child_to_use_established_conventions_used_for_words,_including_spellings,_punctuations_and_grammar."] : "Ability of the child to use established conventions used for words, including spelling, punctuation and grammar."}
                  </div>
                </div>
              )}
            </div>
            <div className="">
              <div
                className="flex justify-between items-center w-full text-left p-4 focus:outline-none bg-[#F6F0FF]"
                onClick={() => this.setActiveIndex(activeIndex === 2 ? null : 2)}
              >
                <p>  {translatedData["Oral_Language"] ? translatedData["Oral_Language"] : "Oral Language"}</p>
                <p>
                  {activeIndex === 2 ? <FaMinus color='#000' /> : <FaPlus color='#000' />}
                </p>
              </div>
              {activeIndex === 2 && (
                <div className="p-4">
                  <div className="pb-2">
                    {translatedData["Listening"] ? translatedData["Listening"] : "Listening"}
                  </div>
                  <div className="border-b pb-2">
                    {translatedData["Ability_of_the_child_to_pay_attention,_comprehend_and_follow_instructions_to_what_is_being_said."] ? translatedData["Ability_of_the_child_to_pay_attention,_comprehend_and_follow_instructions_to_what_is_being_said."] : "Ability of the child to pay attention, comprehend and follow instructions to what is being said."}
                  </div>
                  <div className="pb-2">
                    {translatedData["Speaking"] ? translatedData["Speaking"] : "Speaking"}
                  </div>
                  <div className="pb-2">
                    {translatedData["Appropriate_usage_of_words_(including_pronunciation_and_grammar)_to_convey_a_message."] ? translatedData["Appropriate_usage_of_words_(including_pronunciation_and_grammar)_to_convey_a_message."] : "Appropriate usage of words (including pronunciation and grammar) to convey a message."}
                  </div>
                </div>
              )}
            </div>
            <div className="">
              <div
                className="flex justify-between items-center w-full text-left p-4 focus:outline-none bg-[#F6F0FF]"
                onClick={() => this.setActiveIndex(activeIndex === 3 ? null : 3)}
              >
                <p>{translatedData["Mathematics"] ? translatedData["Mathematics"] : "Mathematics"}</p>
                <p>
                  {activeIndex === 3 ? <FaMinus color='#000' /> : <FaPlus color='#000' />}
                </p>
              </div>
              {activeIndex === 3 && (
                <div className="p-4">
                  <div className="pb-2">
                    {translatedData["Calculations"] ? translatedData["Calculations"] : "Calculations"}
                  </div>
                  <div className="border-b pb-2">
                    {translatedData["Ability_of_the_child_to_identify_mathematical_operations_and_use_them_appropriately."] ? translatedData["Ability_of_the_child_to_identify_mathematical_operations_and_use_them_appropriately."] : "Ability of the child to identify mathematical operations and use them appropriately."}
                  </div>
                  <div className="pb-2">
                    {translatedData["Concepts_/_Logical_Thinking_/_Reasoning"] ? translatedData["Concepts_/_Logical_Thinking_/_Reasoning"] : "Concepts / Logical Thinking /Reasoning"}
                  </div>
                  <div className="pb-2">
                    {translatedData["Ability_of_the_child_to_comprehend_questions_and_apply_mathematical_concepts_appropriately."] ? translatedData["Ability_of_the_child_to_comprehend_questions_and_apply_mathematical_concepts_appropriately."] : "Ability of the child to comprehend questions and apply mathematical concepts app"}
                  </div>
                </div>
              )}
            </div>
            <div className="">
              <div
                className="flex justify-between items-center w-full text-left p-4 focus:outline-none bg-[#F6F0FF]"
                onClick={() => this.setActiveIndex(activeIndex === 4 ? null : 4)}
              >
                <p>
                  {translatedData["Motor_coordination/_Sensory_Integration"] ? translatedData["Motor_coordination/_Sensory_Integration"] : "Motor coordination/ Sensory Integration"}
                </p>
                <p>
                  {activeIndex === 4 ? <FaMinus color='#000' /> : <FaPlus color='#000' />}
                </p>
              </div>
              {activeIndex === 4 && (
                <div className="p-4">
                  <div className="pb-2">
                    {translatedData["Gross_Motor_Thinking_/_Reasoning"] ? translatedData["Gross_Motor_Thinking_/_Reasoning"] : "Gross Motor Thinking/Reasoning"}
                  </div>
                  <div className="border-b pb-2">
                    {translatedData["Appropriate_movement_and_coordination_of_larger_body_muscles,_skills_include_ability_to_walk,_ride_a_bike,_throw_a_ball_etc."] ? translatedData["Appropriate_movement_and_coordination_of_larger_body_muscles,_skills_include_ability_to_walk,_ride_a_bike,_throw_a_ball_etc."] : "Appropriate movement and coordination of larger body muscles, skills include ability to walk, ride a bike, throw a ball etc."}
                  </div>
                  <div className="pb-2">
                    {translatedData["Fine_Motor_Thinking/_Reasoning"] ? translatedData["Fine_Motor_Thinking/_Reasoning"] : "Fine Motor Thinking/ Reasoning"}
                  </div>
                  <div className="border-b pb-2">
                    {translatedData["Appropriate_dexterity_of_smaller_body_muscles,_skills_include_ability_to_holda_pencil,_perform_activities_that_involve_eye_hand_coordination_skill_(tying_laces,_buttoning,_zipping_etc)."] ? translatedData["Appropriate_dexterity_of_smaller_body_muscles,_skills_include_ability_to_holda_pencil,_perform_activities_that_involve_eye_hand_coordination_skill_(tying_laces,_buttoning,_zipping_etc)."] : "Appropriate dexterity of smaller body muscles, skills include ability to hold a pencil, perform activities that involve eye-hand coordination skill (tying laces, buttoning, zipping etc)."}
                  </div>
                  <div className="pb-2">
                    {translatedData["Sensory_Integration_Thinking_/_Reasoning"] ? translatedData["Sensory_Integration_Thinking_/_Reasoning"] : "Sensory Integration Thinking /Reasoning"}
                  </div>
                  <div className="pb-2">
                    {translatedData["Ability_of_the_child_to_respond_to_sensory_stimulation_(sound,_touch,_light_etc)_in_the_environment_appropriately."] ? translatedData["Ability_of_the_child_to_respond_to_sensory_stimulation_(sound,_touch,_light_etc)_in_the_environment_appropriately."] : "Ability of the child to respond to sensory stimulation (sound, touch, light etc) in the environment appropriately."}
                  </div>
                </div>
              )}
            </div>
            <div className="">
              <div
                className="flex justify-between items-center w-full text-left p-4 focus:outline-none bg-[#F6F0FF]"
                onClick={() => this.setActiveIndex(activeIndex === 5 ? null : 5)}
              >
                <p>
                  {translatedData["Social_skills"] ? translatedData["Social_skills"] : "Social skills"}
                </p>
                <p>
                  {activeIndex === 5 ? <FaMinus color='#000' /> : <FaPlus color='#000' />}
                </p>
              </div>
              {activeIndex === 5 && (
                <div className="p-4">
                  <div className="pb-2">
                    {translatedData["Ability_of_the_child_to_maintain_friendship_with_peers,_understand_body_language_and_carry_out_a_conversation_with_individuals_in_his_/_her_environment"] ? translatedData["Ability_of_the_child_to_maintain_friendship_with_peers,_understand_body_language_and_carry_out_a_conversation_with_individuals_in_his_/_her_environment"] : "Ability of the child to maintain friendship with peers, understand body language and carry out a conversation with individuals in his/her environment."}
                  </div>
                </div>
              )}
            </div>
            <div className="">
              <div
                className=" flex justify-between items-center w-full text-left p-4 focus:outline-none bg-[#F6F0FF]"
                onClick={() => this.setActiveIndex(activeIndex === 6 ? null : 6)}
              >
                <p>{translatedData["Attention"] ? translatedData["Attention"] : "Attention"}</p>
                <p>
                  {activeIndex === 6 ? <FaMinus color='#000' /> : <FaPlus color='#000' />}
                </p>
              </div>
              {activeIndex === 6 && (
                <div className="p-4">
                  <div className="pb-2">
                    {translatedData["Inattention_and_Distractibility"] ? translatedData["Inattention_and_Distractibility"] : "Inattention and Distractibility"}
                  </div>
                  <div className="border-b pb-2">
                    {translatedData["Inability_of_the_child_to_focus_and_pay_attention_to_an_activity_(other_than_screen_or_other_activites_of_their_interest)_or_what_is_being_said_in_class_and_other_social_environment."] ? translatedData["Inability_of_the_child_to_focus_and_pay_attention_to_an_activity_(other_than_screen_or_other_activites_of_their_interest)_or_what_is_being_said_in_class_and_other_social_environment."] : "Inability of the child to focus and pay attention to an activity (other than screen or other activites of their interest) or what is being said in class and other social environment."}
                  </div>
                  <div className="pb-2">
                    {translatedData["Impulsivity"] ? translatedData["Impulsivity"] : "Impulsivity"}
                  </div>
                  <div className="border-b pb-2">
                    {translatedData["Tendency_of_a_child_to_act_in_situations_without_thinking_of_the_consequences_that_will_follow_and_also_faces_difficulties_in_turn_taking_activities."] ? translatedData["Tendency_of_a_child_to_act_in_situations_without_thinking_of_the_consequences_that_will_follow_and_also_faces_difficulties_in_turn_taking_activities."] : "Tendency of a child to act in situations without thinking of the consequences that will follow and also faces difficulties in turn taking activities."
                    }
                  </div>
                  <div className="pb-2">
                    {translatedData["Hyperactivity"] ? translatedData["Hyperactivity"] : "Hyperactivity"}
                  </div>
                  <div className="pb-2">
                    {translatedData["Inability_of_the_child_to_be_at_one_place,_seems_to_be_always_'on_the_go'."] ? translatedData["Inability_of_the_child_to_be_at_one_place,_seems_to_be_always_'on_the_go'."] : "Inability of the child to be at one place, seems to be always 'on-the go'."}
                  </div>
                </div>
              )}
            </div>
            <div className="">
              <div
                className="flex justify-between items-center w-full text-left p-4 focus:outline-none bg-[#F6F0FF]"
                onClick={() => this.setActiveIndex(activeIndex === 7 ? null : 7)}
              >
                <p>
                  {translatedData["Executive_Functioning"] ? translatedData["Executive_Functioning"] : "Executive Functioning"}
                </p>
                <p>
                  {activeIndex === 7 ? <FaMinus color='#000' /> : <FaPlus color='#000' />}
                </p>
              </div>
              {activeIndex === 7 && (
                <div className="p-4">
                  <div className="border-b pb-2">
                    {translatedData["Ability_of_the_child_to_multi_task,_make_sense_of_the_environment,_organising_things,_choosing_from_alternatives_and_making_decisions_as_per_their_age_level."] ? translatedData["Ability_of_the_child_to_multi_task,_make_sense_of_the_environment,_organising_things,_choosing_from_alternatives_and_making_decisions_as_per_their_age_level."] : "Ability of the child to multi-task, make sense of the environment, organising things, choosing from alternatives and making decisions as per their age level."}
                  </div>
                </div>
              )}
            </div>
            <div className="">
              <div
                className="flex justify-between items-center w-full text-left p-4 focus:outline-none bg-[#F6F0FF]"
                onClick={() => this.setActiveIndex(activeIndex === 8 ? null : 8)}
              >
                <p>{translatedData["Memory"] ? translatedData["Memory"] : "Memory"}</p>
                <p>
                  {activeIndex === 8 ? <FaMinus color='#000' /> : <FaPlus color='#000' />}
                </p>
              </div>
              {activeIndex === 8 && (
                <div className="p-4">
                  <div className="border-b pb-2">
                    {translatedData["Ability_of_the_child_to_follow_multi_step_commands,_remembering_to_complete_homework_and_other_tasks_assigned_to_them,_perform_during_examinations_and_keeping_their_belongings_safely."] ? translatedData["Ability_of_the_child_to_follow_multi_step_commands,_remembering_to_complete_homework_and_other_tasks_assigned_to_them,_perform_during_examinations_and_keeping_their_belongings_safely."] : "Ability of the child to follow multi-step commands, remembering to complete homework and other tasks assigned to them, perform during examinations and keeping their belongings safely."}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className=' flex justify-center items-center flex-col my-2  '>
            <div className=' text-lg font-bold'>* Disclaimer *</div>
            <p className='text-center'>First Screen App is not diagnostic in nature & can not be used to label any child. The app aims to highlight the strengths & weaknesses of the child in order to facilitate intervention, if required.</p>
          </div>
        </div>
      </section>
    );
  }
}






const mapStateToProps = (state) => ({
  users: state.users,
});

export default connect(mapStateToProps)(MyChart);
