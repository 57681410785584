
import { authHeader, history } from '../_helpers';
import { CONST } from '../_config';
import axios from 'axios';
export const userService = {
    getAllBoard,
    login,
    loginValidateOtp,
    registerValidateOtp,
    validateLoginOtp,
    forgotPassword,
    register,
    logout,
    getUserDetails,
    createNewStudent,
    uploadImage,
    updateProfilePicture,
    updatePassword,
    updateUserInfo,
    saveUserInfo,
    getProfile,
    getUserInfo,
    updateProfile,
    getCountryCodeList,
    getTicketList,
    getAllCountryCodeListApi,
    getMsgListByTicketId,
    createTicket,
    replyByUserForTicket,
    removeImage,
    getLanguageList,
    getStudentList, previousScoreListing,
    getAllIntroduction,
    getLanguageSetting2List,
    getAllQuestion,
    getAllOption,
    submitAnswer,
    reportDataForStudentId,
    updateUser,
    saveBookingAppointment,
    createOrderpaymentVerify,
    getStudetProfile,
    updateStudent
};


async function updateStudent(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    const response = await fetch(CONST.BACKEND_URL + `/updateStudent`, requestOptions);
    const user = await handleResponse(response);
    let userObj = {
        updateStudent: user
    };
    return userObj;
}
async function getStudetProfile(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    const response = await fetch(CONST.BACKEND_URL + `/getStudetProfile`, requestOptions);
    const user = await handleResponse(response);
    let userObj = {
        getStudetProfile: user
    };
    return userObj;
}
async function createOrderpaymentVerify(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    const response = await fetch(CONST.BACKEND_URL + `/createOrder`, requestOptions);
    const user = await handleResponse(response);
    let userObj = {
        createOrderpaymentVerify: user
    };
    return userObj;
}
async function saveBookingAppointment(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    const response = await fetch(CONST.BACKEND_URL + `/saveBookingAppointment`, requestOptions);
    const user = await handleResponse(response);
    let userObj = {
        saveBookingAppointment: user
    };
    return userObj;
}
async function removeImage() {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify()
    }
    const response = await fetch(CONST.BACKEND_URL + `/removeImage`, requestOptions);
    const user = await handleResponse(response);
    let userObj = {
        removeImage: user
    };
    return userObj;
}



async function updateUser(data) {
    let header = new Headers({
        "Content-Type": "application/json",
        Authorization: authHeader().Authorization,
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data),
    };
    const response = await fetch(CONST.BACKEND_URL + `/updateUser`, requestOptions);
    const res = await handleResponse(response)
    let userObj = {
        updateUser: res
    };
    return userObj;
}
async function getLanguageList(data) {
    let header = new Headers({
        "Content-Type": "application/json",
        Authorization: authHeader().Authorization,
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data),
    };
    const response = await fetch(CONST.BACKEND_URL + `/getLanguageList`, requestOptions);
    const res = await handleResponse(response)
    let userObj = {
        languageList: res
    };
    return userObj;
}

async function reportDataForStudentId(data) {
    let header = new Headers({
        "Content-Type": "application/json",
        Authorization: authHeader().Authorization,
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data),
    };
    const response = await fetch(CONST.BACKEND_URL + `/reportDataForStudentId`, requestOptions);
    const res = await handleResponse(response)
    let userObj = {
        reportDataForStudentId: res.data
    };
    return userObj;
}
async function getAllIntroduction(data) {
    let header = new Headers({
        "Content-Type": "application/json",
        Authorization: authHeader().Authorization,
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data),
    };
    const response = await fetch(CONST.BACKEND_URL + `/getAllIntroduction`, requestOptions);
    const res = await handleResponse(response)
    let userObj = {
        getAllIntroduction: res.data
    };
    return userObj;
}


async function getLanguageSetting2List(data) {
    let header = new Headers({
        "Content-Type": "application/json",
        Authorization: authHeader().Authorization,
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data),
    };
    const response = await fetch(CONST.BACKEND_URL + `/getLanguageSetting2`, requestOptions);
    const res = await handleResponse(response)
    let userObj = {
        getLanguageSetting2List: res.data
    };
    return userObj;
}
async function getAllQuestion(data) {
    let header = new Headers({
        "Content-Type": "application/json",
        Authorization: authHeader().Authorization,
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data),
    };
    const response = await fetch(CONST.BACKEND_URL + `/getAllQuestion`, requestOptions);
    const res = await handleResponse(response)
    let userObj
    if (res && res.data) {

        const question = [...res.data.setId1, ...res.data.setId2, ...res.data.setId3]
        userObj = {
            getAllQuestion: { question, splashScreens: res.data.splashData }
        };
    }

    return userObj;
}


async function submitAnswer(data) {
    let header = new Headers({
        "Content-Type": "application/json",
        Authorization: authHeader().Authorization,
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data),
    };
    const response = await fetch(CONST.BACKEND_URL + `/submitAnswer`, requestOptions);
    const res = await handleResponse(response)
    let userObj = {
        getAllOption: res.data
    };
    return userObj;
}


async function getAllOption(data) {
    let header = new Headers({
        "Content-Type": "application/json",
        Authorization: authHeader().Authorization,
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data),
    };
    const response = await fetch(CONST.BACKEND_URL + `/getAllOption`, requestOptions);
    const res = await handleResponse(response)
    let userObj = {
        getAllOption: res.data
    };
    return userObj;
}

async function getStudentList(data) {
    let header = new Headers({
        "Content-Type": "application/json",
        Authorization: authHeader().Authorization,
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data),
    };
    const response = await fetch(CONST.BACKEND_URL + `/getStudentList`, requestOptions);
    const res = await handleResponse(response)
    let userObj = {
        studentList: res.data
    };
    return userObj;
}


async function previousScoreListing(data) {
    let header = new Headers({
        "Content-Type": "application/json",
        Authorization: authHeader().Authorization,
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data),
    };
    const response = await fetch(CONST.BACKEND_URL + `/previousScoreListing`, requestOptions);
    const res = await handleResponse(response)
    let userObj = {
        screenList: res.data
    };
    return userObj;
}



async function getAllBoard(data) {
    let header = new Headers({
        "Content-Type": "application/json",
        Authorization: authHeader().Authorization,
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data),
    };
    const response = await fetch(CONST.BACKEND_URL + `/getAllBoard`, requestOptions);
    // console.log("00000000000000000000", data)
    const res = await handleResponse(response)
    let userObj = {
        res
    };
    return userObj;
}



async function getTicketList(data) {
    let header = new Headers({
        "Content-Type": "application/json",
        Authorization: authHeader().Authorization,
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data),
    };
    const response = await fetch(CONST.BACKEND_URL + `/getTicketList`, requestOptions);
    const data_1 = await handleResponse(response);
    let userObj = {
        ticketlistData: data_1.data,
    };
    return userObj;
}


async function getMsgListByTicketId(data) {
    let header = new Headers({
        "Content-Type": "application/json",
        Authorization: authHeader().Authorization,
    });

    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data),
    };
    const response = await fetch(CONST.BACKEND_URL + `/getMsgListByTicketId`, requestOptions);
    const data_1 = await handleResponse(response);
    let userObj = {
        ticketbyList: data_1.data,
    };
    console.log("in service getMsgListByTicketIdgetMsgListByTicketIdgetMsgListByTicketId", data_1.data);
    return userObj;
}



async function replyByUserForTicket(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    const response = await fetch(CONST.BACKEND_URL + `/replyByUserForTicket`, requestOptions);
    const data_1 = await handleResponse(response);
    let userObj = {
        ticketData: data_1.data
    };
    return userObj;
}


async function createTicket(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    const response = await fetch(CONST.BACKEND_URL + `/createTicket`, requestOptions);
    const data_1 = await handleResponse(response);
    let userObj = {
        ticketData: data_1.data
    };
    return userObj;
}





async function getUserInfo(data) {
    const options = {
        url: CONST.BACKEND_URL + `/getUserInfo`,
        method: 'POST',
        headers: {

            'Content-Type': 'application/json',
            "Authorization": authHeader().Authorization
        },
        data: JSON.stringify(data)

    };
    const users_1 = await axios(options);
    console.log("@!@!#users!", users_1.data);
    let bucketObj = {
        getUserInfo: users_1.data.data
    };
    return bucketObj;
}





async function updateProfile(formData) {
    const options = {
        url: CONST.BACKEND_URL + `/updateProfile`,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            "Authorization": authHeader().Authorization
        },
        data: JSON.stringify(formData)
    };
    const response = await axios(options);
    // console.log('UPDATE PROFILE IN SERVICE', response.data);
    let bucketObj = {
        updateProfile: response.data
    };
    return bucketObj;
}








async function getProfile() {
    const options = {
        url: CONST.BACKEND_URL + `/getProfile`,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            "Authorization": authHeader().Authorization
        },
        // data: JSON.stringify(data)
    };
    const response = await axios(options);
    // // console.log('GET PROFILE IN SERVICE', response.data);
    let bucketObj = {
        getProfile: response.data
    };
    return bucketObj;
}

async function register(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    const response = await fetch(CONST.BACKEND_URL + `/register`, requestOptions);
    const user = await handleResponse(response);
    let userObj = {
        userinfo: user
    };
    return userObj;
}

function logout() {
    window.sessionStorage.removeItem('user');
    history.push(`#/login`);

}



function onerrorlogout() {
    window.sessionStorage.removeItem('user');
    history.push(`#/login`);
    window.location.reload();
}





async function login(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    console.log('requestOptions', requestOptions);
    const response = await fetch(CONST.BACKEND_URL + `/userRegister`, requestOptions);
    const user = await handleResponse(response);
    console.log('user________________________111', user);
    let userObj = {
        userinfo: user
    };
    return userObj;

}

// function loginValidateOtp(data) {

//     let header = new Headers({
//         'Content-Type': 'application/json',
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: JSON.stringify(data)
//     }
//     console.log('requestOptions', requestOptions);
//     return fetch(CONST.BACKEND_URL + `/userRegister`, requestOptions)
//         .then(handleResponse)
//         .then(user => {
//             console.log('user________________________111', user);
//             let userObj = {
//                 userinfo: user
//             }
//             if (user.data) {
//                 window.sessionStorage.setItem('user', JSON.stringify(user.data));
//             }

//             return userObj;
//         });

// }

async function getCountryCodeList() {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
    }
    const response = await fetch(CONST.BACKEND_URL + `/getCountryCodeList`, requestOptions);
    const user = await handleResponse(response);
    let userObj = {
        getCountryCodeList: user
    };
    return userObj;
}










async function validateLoginOtp(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    const response = await fetch(CONST.BACKEND_URL + `/validateLoginOtp`, requestOptions);
    const user = await handleResponse(response);
    let userObj = {
        userinfo: user
    };
    if (user.data) {
        window.sessionStorage.setItem('user', JSON.stringify(user.data));
    }
    return userObj;
}



async function loginValidateOtp(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    const response = await fetch(CONST.BACKEND_URL + `/registerValidateOtp`, requestOptions);
    const user = await handleResponse(response);
    let userObj = {
        userinfo: user
    };
    if (user.data) {
        window.sessionStorage.setItem('user', JSON.stringify(user.data));
    }
    return userObj;
}





async function registerValidateOtp(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    const response = await fetch(CONST.BACKEND_URL + `/validateRegisterOtp`, requestOptions);
    const user = await handleResponse(response);
    let userObj = {
        userinfo: user
    };
    if (user.data) {
        window.sessionStorage.setItem('user', JSON.stringify(user.data));
    }
    return userObj;
}

async function forgotPassword(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    const response = await fetch(CONST.BACKEND_URL + `/forgotPassword`, requestOptions);
    const user = await handleResponse(response);
    let userObj = {
        userinfo: user
    };
    return userObj;
}








async function createNewStudent(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    const response = await fetch(CONST.BACKEND_URL + `/createNewStudent`, requestOptions);
    const user = await handleResponse(response);
    let userObj = {
        userinfo: user.data
    };
    return userObj;
}

async function getUserDetails(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    const response = await fetch(CONST.BACKEND_URL + `/getProfile`, requestOptions);
    const data_1 = await handleResponse(response);
    let userObj = {
        createNewStudent: data_1.data
    };
    return userObj;
}


async function updateUserInfo(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    const response = await fetch(CONST.BACKEND_URL + `/updateUserInfo`, requestOptions);
    const data_1 = await handleResponse(response);
    let userObj = {
        updateUserInfo: data_1
    };
    return userObj;
}


async function saveUserInfo(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    const response = await fetch(CONST.BACKEND_URL + `/saveUserInfo`, requestOptions);
    const data_1 = await handleResponse(response);
    let userObj = {
        saveUserInfoData: data_1.data
    };
    return userObj;
}




async function updatePassword(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    const response = await fetch(CONST.BACKEND_URL + `/changePassword`, requestOptions);
    const data_1 = await handleResponse(response);
    let userObj = {
        userinfo: data_1
    };
    return userObj;
}





async function uploadImage(filedata) {
    console.log(filedata)

    let header = new Headers({
        "Authorization": authHeader().Authorization,
    })
    const requestOptions = {
        method: "POST",
        headers: header,
        body: filedata
    }
    return fetch("https://orkids.thefirstscreen.com/api/upload", requestOptions)
        .then(handleResponse)
        .then(res => {
            let userObj = {
                filesDetails: res
            }
            return userObj;
        });
}

async function updateProfilePicture(filedata) {


    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(filedata)
    }
    return fetch(CONST.BACKEND_URL + `/updateProfileImage`, requestOptions)
        .then(handleResponse)

        .then(data => {
            let userObj = {
                userData: data
            }
            return userObj;
        });
}
async function getAllCountryCodeListApi(filedata) {

    let header = new Headers({
        "Authorization": authHeader().Authorization,
    })
    const requestOptions = {
        method: "POST",
        headers: header,
        body: filedata
    }
    return fetch(CONST.BACKEND_URL + `/getAllCountryCodeListApi`, requestOptions)
        .then(handleResponse)
        .then(res => {
            let userObj = {
                filesDetails: res
            }
            return userObj;
        });
}


function handleResponse(response) {

    return response.text().then(text => {
        const data = text && JSON.parse(text);
        // console.log("handleResponse_handleResponse_handleResponse:::", data);
        if (!response.ok) {
            if (response.status === 401) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        if (data.error) {
            if (data.code === 3) {
                onerrorlogout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}








// New Api



