import React  from 'react';
import { ImCross } from "react-icons/im";
import { FaEdit } from "react-icons/fa";
import { useSelector } from 'react-redux';




export default function ReceiveModal({ handleCloseReceive, updateinfo, handleChange, handleSubmit, handleOpenPayment, props, walletAdd, clickRef, handleClickFile, handleChangePhoto }) {

  let selector = useSelector(state => state)
  let { users } = selector ? selector : {}
  let { filesDetails } = users ? users : {}
  let { imageURL } = filesDetails ? filesDetails : {}



  return (
    <>
      <div className={handleOpenPayment ? "fixed w-full top-0 left-0 h-full inset-0 z-50 overflow-hidden mt-0 flex justify-center items-center overflow-y-auto bg-black/40 md:p-0 p-1" : "hidden"} style={{ marginTop: '0rem', }}  >
        <div className="md:w-[32rem] w-11/12  mx-auto rounded-[24px] shadow-lg  overflow-y-auto mt-2">
          <div className="flex items-center justify-between p-6 py-2 bg-[#F6F0FF] ">
            <p className="text-xl font-bold text-[#4E2C7A]">Update</p>
            <div className="rounded-full bg-white p-1 shadow-lg" onClick={() => handleCloseReceive()}>
              <ImCross className='text-[#4E2C7A]' size={12} />
            </div>
          </div>

          <div className='bg-[#fff] px-2 py-4 justify-between items-center rounded-b'>

            <div className='flex relative justify-center items-start'>
              {(imageURL || updateinfo?.profileImg) ? <img src={imageURL || updateinfo?.profileImg} alt="" className='rounded-full h-28 w-28' />
                :
                <img src="/img/suresh-singh.png" alt="" className='rounded-full h-28 w-28' />
              }
              <input type='file' onChange={handleChangePhoto} ref={clickRef} className='hidden ' />
              <span className='absolute -bottom-2 border border-gray-800 right-[46%] bg-white p-2 rounded-full text-center  h-9 w-9' >

                <FaEdit className='    text-[#4E2C7A] cursor-pointer' onClick={() => handleClickFile()} size={20} />
              </span>
            </div>
            <div className=''>
              <form onSubmit={handleSubmit} class="max-w-sm mx-auto">
                <div class="mb-5">
                  <label for="text" class="block mb-2 text-sm font-medium text-gray-900 ">*Full Name</label>
                  <input type="text" onChange={handleChange} name="fullName" value={updateinfo && updateinfo ? updateinfo["fullName"] : ""} id="text" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-[#F6F0FF] dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Test User" required />
                </div>
                <div class="mb-5">
                  <label for="email" class="block mb-2 text-sm font-medium text-gray-900 ">*Email</label>
                  <input type="email" onChange={handleChange} name='email' value={updateinfo && updateinfo ? updateinfo["email"] : ""} id="email" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-[#F6F0FF] dark:border-gray-600 dark:placeholder-gray-400 " placeholder="example@gmail.com" required />
                </div>
                

                <button type="submit" class="text-white bg-[#4E2C7A] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full  px-5 py-2.5 text-center dark:hover:bg-[#4E2C7A] ">Update</button>
              </form>

            </div>

          </div>
        </div>
      </div>
    </>

  );
}
