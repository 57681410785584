import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { MdOutlineChevronLeft } from "react-icons/md";
import { CONST } from '../../_config'
class Dashboard extends Component {

  state = {
    data: null,
    loading: true,
    error: null
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    const url = CONST.BACKEND_URL;
    const token = `Bearer ${(JSON.parse(window.sessionStorage.getItem("user"))).token}`

    try {
      const response = await axios.post(
        `${url}/getAllService`,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token
          }
        }
      );

      if (response.data && !response.data.error) {
        this.setState({ data: response.data.data, loading: false });
      } else {
        this.setState({ error: response.data.message, loading: false });
        console.error('Error fetching data:', response.data.message);
      }
    } catch (error) {
      this.setState({ error: error.message, loading: false });
      console.error('API call error:', error);
    }
  };


  onClickMenu = (url) => {
    this.props.history.push(url);
  };

  handleItemClick = (_id) => {
    this.props.history.push(`/app/service/${_id}`);
  }

  render() {
    const { data, loading, error } = this.state;
    console.log("dataf", data)
    return (
      <>
        <div className="h-screen xl:py-10 py-6 overflow-y-auto overflow-hidden">
          <div className='lg:w-7/12 w-11/12 mx-auto'>
            <div className='bg-[#F6F0FF] px-2 py-4 space-y-6'>
              <div className="flex items-center justify-between p-6 py-2">
                <div className="rounded-full p-1 bg-[#fff] shadow-lg" onClick={() => this.onClickMenu('/app/dashboard')}>
                  <MdOutlineChevronLeft className='text-[#4E2C7A]' size={30} />
                </div>
                <div className="">
                  <h1>Orkids Service</h1>
                </div>
                <div>
                </div>
              </div>
            </div>

            <div className='grid lg:grid-cols-2 grid-cols-2 gap-10 py-10'>
              {loading && <p>Loading...</p>}
              {error && <p>Error: {error}</p>}
              {this.state.data && Array.isArray(this.state.data) && this.state.data.length > 0 && (
                this.state.data.map((ele) => {
                  return (<div className='bg-[#F6F0FF] rounded-[10px] shadow-md p-6 flex flex-col items-center space-y-2' onClick={() => this.handleItemClick(ele._id)}>
                    <img className='w-24 h-24' src={ele && ele.image && ele.image.en ? ele.image.en : ""} alt={ele && ele.service && ele.service.en ? ele.service.en : ""} />
                    <p className='text-[#4E2C7A] text-[18px]'>{ele && ele.service && ele.service.en ? ele.service.en : ""}</p>
                  </div>)
                })
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { users } = state;
  const { overview } = users ? users : {};
  return {
    users,
    user: overview ? overview.user : null,
  };
}

export default connect(mapStateToProps)(Dashboard);
