import React, { Component } from "react";
import { withRouter } from "react-router-dom"; // Import withRouter for navigation
import { ArrowLeft } from "lucide-react";

const countries = [
  { name: "India", code: "IN" },
  { name: "Turkey", code: "TR" },
  { name: "Spain", code: "ES" },
  { name: "USA", code: "US" },
];

class CountrySelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCountry: null,
    };
  }


  handleCountrySelect = (code) => {
    this.setState({ selectedCountry: code });
  };

  handleContinue = () => {
    const { selectedCountry } = this.state;

    const queryParams = new URLSearchParams(this.props.location.search);
    const email = queryParams.get("email");

    if (selectedCountry) {
      this.props.history.push({
        pathname: `/signup/${email}`,
        state: { countryCode: selectedCountry },
      });
    } else {
      alert("Please select a country first!");
    }
  };

  render() {
    console.log("this.props", this.props)
    return (
      <div className="min-h-screen bg-[#F6F0FF] p-6">
        <div className="w-full bg-[#F6F0FF] py-4 px-6 flex items-center ">
          <ArrowLeft className="text-purple-700 cursor-pointer" />
          <h1 className="text-lg text-[#231F20] font-semibold ml-3">
            Select Country
          </h1>
        </div>

        <div className="grid grid-cols-2 gap-x-0 gap-y-12 mt-8 place-content-center ">
          {countries.map((country) => (
            <div
              key={country.code}
              className="flex flex-col items-center space-y-1"
            >
              <div
                className={`w-[84px] h-[93px] flex items-center mb-4 justify-center cursor-pointer transition-all ${this.state.selectedCountry === country.code
                  ? "bg-[#8F57C7]"
                  : "bg-[#D9D9D9]"
                  }`}
                onClick={() => this.handleCountrySelect(country.code)}
              ></div>

              <p className="text-sm font-poppins text-[#010101] font-bold mb-2">
                {country.name}
              </p>
            </div>
          ))}
        </div>

        <button
          className="w-40 py-3 mx-auto text-white bg-gradient-to-r from-purple-500 to-indigo-600 hover:from-indigo-600 hover:to-purple-500 rounded-lg font-semibold text-lg shadow-md transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-purple-300"
          onClick={this.handleContinue}
        >
          Continue
        </button>

      </div>
    );
  }
}

export default withRouter(CountrySelect);
