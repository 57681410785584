export const CONST = {

   BACKEND_URL: 'https://orkids.thefirstscreen.com/api/v1',
  // BACKEND_URL: 'https://wallet.leytia.com/api/v1',
  //BACKEND_URL: 'https://orkid.jamsara.com/api/v1',
  // BACKEND_URL: 'http://localhost:5204/api/v1',
  // BACKEND_URL: 'https://gcn-wallet-backend.vercel.app/api/v1',


};
